// Chakra imports
import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useState } from 'react';

// Assets
import {MdShop2} from "react-icons/md";

//custom imports
import StoreList from './components/StoreList';
import StoresBannerList from "./components/StoreBannerList";

export default function Shops() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [count, setStoreCount] = useState(0)

  const storeCount = (count) =>{
    setStoreCount(count)
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
  
      <StoresBannerList/>
      {/* <StoreList setStoreCount={storeCount}/> */}

    </Box>
  );
}
