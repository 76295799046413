// Chakra imports
import {
  Box,
} from "@chakra-ui/react";



import React, { useState, useEffect } from 'react';
import {useToast} from "@chakra-ui/react"

import MiniStatisticsComponent from "./components/MiniStatistics"


import { GET_REPORTS_OF_A_SPECIFIC_STORE } from 'api/ApiUtils';


export default function UserReports() {
  const toast = useToast();
  const [totalProfits, setTotalProfits] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalSalesQuantity, setTotalSalesQuantity] = useState(0);
  const [totalDailyProfits, setTotalDailyProfits] = useState(0);
  const [totalDailySales, setTotalDailySales] = useState(0);
  const [totalDailySalesQuantity, setTotalDailySalesQuantity] = useState(0);

  useEffect(() => {
    async function fetchMyAPI() {
      let data = {}
      let store_id = JSON.parse(localStorage.getItem('active_store_id'));
      data.store_id = store_id;
      let response = await GET_REPORTS_OF_A_SPECIFIC_STORE(data);
      
      if (response.data?.status === "success"){
        const reports = response.data.data.reports;
        const daily_reports = response.data.data.daily_reports.dailySalesData[0];
        setTotalProfits(reports.profitSum);
        setTotalSales(reports.totalCount);
        setTotalSalesQuantity(reports.totalQuantitySold);
        setTotalDailyProfits(daily_reports?.total_profit);
        setTotalDailySales(daily_reports?.sales_count);
        setTotalDailySalesQuantity(daily_reports?.total_quantity);
      }else if(response.data?.status === "failed") {
        toast({
          title: 'Error occured.',
          description: `${response.data.data.message}`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      } else {
        if(response.error.response.data.data?.message) {
          toast({
            title: 'Error occured.',
            description: `${response.error.response.data.data.message}`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        } else if(response.error.response.data.errors) {
          let errors = response.error.response.data.errors;
          errors.forEach((error) => {
            toast({
              title: 'Error occured.',
              description: `${error}`,
              status: 'error',
              duration: 6000,
              isClosable: true,
            })
          })
        } else {
          toast({
            title: 'Error occured.',
            description: `Try again later`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        }
      }

    }

    fetchMyAPI()
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      <MiniStatisticsComponent
        totalProfits = {totalProfits}
        totalProfitsName = {"Total Profits"}
        totalSalesQuantity = {totalSalesQuantity}
        totalSalesQuantityName = {"Total Sales Quantity"}
        totalSales = {totalSales}
        totalSalesName = {"Total Sales"}

      />

      <MiniStatisticsComponent
        totalProfits = {totalDailyProfits}
        totalProfitsName = {"Total Daily Profits"}
        totalSalesQuantity = {totalDailySalesQuantity}
        totalSalesQuantityName = {"Total Daily Sales Quantity"}
        totalSales = {totalDailySales}
        totalSalesName = {"Total Daily Sales"}

      />

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
