// Chakra imports
import {
    Avatar,
    Box,
    Flex,
    FormLabel,
    Icon,
    Select,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react";

  // Assets
  import Usa from "assets/img/dashboards/usa.png";
  // Custom components
  import MiniCalendar from "components/calendar/MiniCalendar";
  import MiniStatistics from "components/card/MiniStatistics";
  import IconBox from "components/icons/IconBox";
  import React, { useState, useEffect } from 'react';
  
  import {
    MdAddTask,
    MdAttachMoney,
    MdBarChart,
    MdFileCopy,
  } from "react-icons/md";
  import CheckTable from "views/admin/default/components/CheckTable";
  import ComplexTable from "views/admin/default/components/ComplexTable";
  import DailyTraffic from "views/admin/default/components/DailyTraffic";
  import PieCard from "views/admin/default/components/PieCard";
  import Tasks from "views/admin/default/components/Tasks";
  import TotalSpent from "views/admin/default/components/TotalSpent";
  import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
  import {
    columnsDataCheck,
    columnsDataComplex,
  } from "views/admin/default/variables/columnsData";
  import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
  import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
  
  
  export default function MiniStatisticsComponent(props) {
    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    
    return (
        <Box>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
          gap='20px'
          mb='20px'>
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
                }
              />
            }
            name={props.totalProfitsName}
            value={`${props.totalProfits} ksh`} 
          />

          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
                }
              />
            }
            name={props.totalSalesName}
            value={props.totalSales}
          />
           
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
                }
              />
            }
            name={props.totalSalesQuantityName}
            value={props.totalSalesQuantity}
          />
  
        {/*  <MiniStatistics
            startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
                  }
                />
              }
            name='Total v2 Accepted'
            value={`${props.totalLoansAcceptedV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`}
          />
          <MiniStatistics
            startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
                  }
                />
              }
            name='Total V2 Fully Paid'
            value={`${props.totalLoansFullyPaidV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`}
          />
          <MiniStatistics
             startContent={
                <IconBox
                  w='56px'
                  h='56px'
                  bg={boxBg}
                  icon={
                    <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
                  }
                />
              }
            name='Total V2 Onpayment'
            value={`${props.totalLoansOnPaymentV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`}
          />
        </SimpleGrid>

        <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Total V2 denied'
          value={`${props.totalLoansDeniedV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`}

        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Total V2 Business Pending '
          value={`${props.totalLoansBusinessPendingV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`} 
        />
        <MiniStatistics 
           startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Total V2 fined' 
          value={`${props.totalLoansFinedV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`} 
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Total v2 fined and fully paid'
          value={`${props.totalLoansFinedAndFullyPaidV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`}
        />
        <MiniStatistics
           startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Total V2 fined and on payment'
          value={`${props.totalLoansFinedAndOnPaymentV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Total V2 fined and no payments'
          value={`${props.totalLoansFinedAndBusinessAcceptedV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`}
        />
      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
           startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Total V2 Accrued Interest'
          value={`${props.totalLoansAccruedInterestV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`}

        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='TODO: Total V2 Accrued Fine '
          value={`${props.totalLoansAccruedFineV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`} 
        />
        <MiniStatistics 
           startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          } 
          name='TODO: Total V2 Accrued Fine and Interest' 
          value={`${props.totalLoansAccruedFinePlusInterestV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`} 
        />

        <MiniStatistics
           startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Total v2 Realized Interest'
          value={`${props.totalLoansRealizedInterestV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`}
        />
        <MiniStatistics
           startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='TODO: Total V2 Realized Fine'
          value={`${props.totalLoansRealizedFineV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`}
        />
        <MiniStatistics
           startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='TODO: Total V2 Realized Fine and Interest'
          value={`${props.totalLoansRealizedFinePlusInterestV2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ksh`}
        /> */}
      </SimpleGrid>
      </Box>
    );
  }
  