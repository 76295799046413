export const columnsDataTransactions = [
  {
    Header: "SALE",
    accessor: "sale_id",
  },
  {
    Header: "RESTOCK",
    accessor: "restock_id",
  },
  {
    Header: "TRX CODE",
    accessor: "trx_code",
  },
  {
    Header: "THIRD PARTY NAME",
    accessor: "third_party_name",
  },{
    Header: "THIRD PARTY TRX CODE",
    accessor: "third_party_trx_code",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "REGISTRATION DATE",
    accessor: "createdAt",
  },
  {
    Header: "VIEW",
    accessor: "id",
  }
];
