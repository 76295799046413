// Chakra imports
import { Avatar, Box, Flex, Text, Icon, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

import IconBox from "components/icons/IconBox";
import { MdEdit } from "react-icons/md";

export default function Banner(props) {
  const { banner, avatar, name, job, phone, national_id, system_id } = props;
  // Chakra Color Mode
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={avatar}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {name} 
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
        {job}
        <IconBox
            me='12px'
            w='38px'
            h='38px'
            bg={boxBg}
            ms='auto'
            icon={<Icon as={MdEdit} color={brandColor} w='18px' h='18px' />}
          />
      </Text>
      <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='1xl' fontWeight='700'>
            {phone}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Phone
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='1xl' fontWeight='700'>
            {national_id}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Natioal ID
          </Text>
        </Flex>
        <Flex mx='auto' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='1xl' fontWeight='700'>
            {system_id}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            System ID
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
