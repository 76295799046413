// Chakra imports
import {
    Box,
    Flex,
    Text,
    Icon,
    useColorModeValue,
    Checkbox,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/card/Card.js";
  import Menu from "components/menu/MainMenu";
  import IconBox from "components/icons/IconBox";
  import CheckTable from "./CheckTable";
  import {
    columnsDataCheck,
    columnsDataLoans,
  } from "../variables/columnsData";
  
  // Assets
  import { MdDragIndicator } from "react-icons/md";
  import React, { useState, useEffect } from 'react';
  import { IoMdEye } from "react-icons/io";

  //API calls
import { LoanList } from 'api/ApiUtils';
  
  export default function UserListFunc(props) {
    const { ...rest } = props;

  const [loanList, setLoanList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0)
  const [limit, setLimit] = useState(50)
  const [Pages, setPages] = useState(0)

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await LoanList(pageNo, 0)
      setLoanList(response.data.data)
      props.setLoanCount(response.data.metadata.count)
      setRecordsCount(response.data.metadata.count)

      //get all the loans from the database
      // let allLoans = await LoanList(pageNo, response.data.metadata.count)
      // setRecordsCount(response.data.data.news_feeds.total_news_feeds)
      // setPages(response.data.data.news_feeds.pages)
    }
    fetchMyAPI()
  }, [])
  
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
    const brandColor = useColorModeValue("brand.500", "brand.400");
    return (
      <Card p='20px' align='center' direction='column' w='100%' {...rest}>
        <CheckTable columnsData={columnsDataLoans} tableData={loanList}/>
      </Card>
    );
  }
  