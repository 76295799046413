import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function DateRangePickerComponent ({ startDate, endDate, onStartDateChange, onEndDateChange }){
  // State to store the selected start and end dates
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  // Function to handle start date selection
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    onStartDateChange(date);
  };

  // Function to handle end date selection
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    onEndDateChange(date);
  };

  return (
    <div>
      <h2>Select Dates</h2>
      <div>
        <label>Start Date:</label>
        <DatePicker
          selected={selectedStartDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          dateFormat="MMMM d, yyyy"
          placeholderText="Select start date"
        />
      </div>
      <div>
        <label>End Date:</label>
        <DatePicker
          selected={selectedEndDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          minDate={selectedStartDate}
          dateFormat="MMMM d, yyyy"
          placeholderText="Select end date"
        />
      </div>
    </div>
  );
};

