// Chakra imports
import {
  Box,
} from "@chakra-ui/react";

import React from 'react';

//custom imports
import AddProductSaleForm from './components/AddProductSaleForm';

export default function Links(props) {

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <AddProductSaleForm props={props} />

    </Box>
  );
}
