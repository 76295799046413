import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Flex,
  Button,
  Text,
  Select,
  FormControl,
  FormLabel,
  Input,
  Link,
  Icon,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import moment from "moment";

import IconBox from "components/icons/IconBox";
import { IoMdEye, IoMdArrowForward } from "react-icons/io";

// Custom components
import Card from "components/card/Card";
import Modal from "components/Modal";

  //API calls
  import { 
    GET_PRODUCTS_OF_A_SPECIFIC_STORE,
    GET_STORES_OF_A_SPECIFIC_USER,
    GET_CUSTOMERS_OF_A_SPECIFIC_STORE,
    ADD_PRODUCT_SALE,
    PATCH_PRODUCT_SALES
  } from 'api/ApiUtils';

export default function AddProductRestockForm(props) {
  const toast = useToast()
  const history = useHistory();

  const [product_sale_data, setProductSaleData] = useState({});
  const [sale_id, setProductSaleId] = useState('');
  const [product_data, setProductData] = useState({});
  const [store_data, setStoreData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [customer_data, setCustomerData] = useState({});
  const [isOpenState, setIsOpenState] = useState(false);
  const [productName, setProductName] = useState('');
  const [product_id, setProductId] = useState('');
  const [storeName, setStoreName] = useState('');
  const [store_id, setStoreId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customer_id, setCustomerId] = useState(0);
  const [quantity, setQuantity] = useState(null);
  const [price_per_unit, setPricePerUnit] = useState('');
  const [payment_mode, setPaymentMode] = useState('');
  const [payment_status, setPaymentStatus] = useState('');
  const [paymentStatusName, setPaymentStatusName] = useState('');
  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [sale_date, setSaleDate] = useState('');

  useEffect(() => {
    async function fetchMyAPI() {
      if (props.props?.location?.state?.action === "edit") {

        const saleInfo = props.props.location.state.data
        let saleData = {};
        saleInfo.map((row, index) => {
          let id = row.column.id
          let value = row.value
          saleData[id] = value

        })
        setIsEdit(true)
        setCustomerData(saleData.customer)
        setStoreData(saleData.store)
        setProductData(saleData.product);
        setProductSaleData(saleData)
        setProductSaleId(saleData.id)
        setProductId(saleData.product.id)
        setStoreId(saleData.store.id)
        setCustomerId(saleData.customer?.id)
        setQuantity(saleData.quantity)
        setPricePerUnit(saleData.price_per_unit)
        setPaymentMode(saleData.payment_mode)
        setPaymentStatus(saleData.payment_status)
        setSaleDate(saleData.sale_date)
      }
      let store_id = JSON.parse(localStorage.getItem('active_store_id'));
      let user = JSON.parse(localStorage.getItem('user'));
      let data = {};
      data.store_id = store_id
      data.user_id = user.id
      data.limit = 50;
      data.pageNo = 0;
      let response = await GET_PRODUCTS_OF_A_SPECIFIC_STORE(data);
      setProducts(response.data.data.products.rows);

      response = await GET_STORES_OF_A_SPECIFIC_USER(data);
      setStores(response.data.data.stores.rows);

      response = await GET_CUSTOMERS_OF_A_SPECIFIC_STORE(data);
      setCustomers(response.data.data.customers.rows);
      
    }
    fetchMyAPI()
  }, [])

  const handleQuantityChange = (event) => setQuantity(event.target.value);
  const handlePricePerUnitChange = (event) => setPricePerUnit(event.target.value);
  const handlePaymentModeChange = (event) => setPaymentMode(event.target.value);
  const handleSaleDateChange = (event) => setSaleDate(event.target.value);
  const handlePaymentStatusChange = (event) => {
    setPaymentStatus(event.target.value.split('-')[0]);
    setPaymentStatusName(event.target.value);
  }
  const handleProductIdChange = (event) => {
    setProductId(event.target.value.split('-')[0]);
    setProductName(event.target.value);
  }
  const handleStoreIdChange = (event) => {
    setStoreId(event.target.value.split('-')[0]);
    setStoreName(event.target.value);
  }
  const handleCustomerIdChange = (event) => {
    setCustomerId(event.target.value.split('-')[0]);
    setCustomerName(event.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let data = {
      product_id,
      sale_id,
      customer_id,
      quantity,
      payment_mode,
      payment_status,
      price_per_unit,
      sale_date,
      status: 1
    }
    let store_id = JSON.parse(localStorage.getItem('active_store_id'));
    data.store_id = store_id;
    try {
      let response
      // response = await PATCH_PRODUCT_SALES(data) 
      if (isEdit) { 
        response = await PATCH_PRODUCT_SALES(data)
      } else {
        response = await ADD_PRODUCT_SALE(data);
      }
      
      if(response.data?.status === "success") {
        history.push({pathname:'/admin/product-sales'})
      }else if(response.data?.status === "failed") {
        toast({
          title: 'Error occured.',
          description: `${response.data.data.message}`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      } else {
        if(response.error.response.data.data?.message) {
          toast({
            title: 'Error occured.',
            description: `${response.error.response.data.data.message}`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        } else if(response.error.response.data.errors) {
          let errors = response.error.response.data.errors;
          errors.forEach((error) => {
            toast({
              title: 'Error occured.',
              description: `${error}`,
              status: 'error',
              duration: 6000,
              isClosable: true,
            })
          })
        } else {
          toast({
            title: 'Error occured.',
            description: `Try again later`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        }
      }
    }catch (err) {
      console.error(err);
      toast({
        title: 'Error occured.',
        description: err+'An error occurred during submission. Please try again.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      })
    }
  
  }

  // Chakra color mode
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const handleModalClick = () => {

    setIsOpenState(!isOpenState)
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='30px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex alignItems='center' w='100%' mb='30px'>
        <IconBox
          me='12px'
          w='38px'
          h='38px'
          bg={boxBg}
          icon={<Icon as={IoMdEye} color={brandColor} w='24px' h='24px' />}
        />
        <Text color={textColor} fontSize='lg' fontWeight='700'>
          {props.props?.location?.state?.action === "edit" ? "Edit Product Sale" : "Add a Prodct Sale"}
        </Text>
        <Link
          w='100%'
          href='#/admin/product-sales'>  
          <IconBox
            me='12px'
            w='38px'
            h='38px'
            bg={boxBg}
            ms='auto'
            icon={<Icon as={IoMdArrowForward} color={brandColor} w='24px' h='24px' />}
          />
          </Link>
      </Flex>
      <Flex
        zIndex='2'
        direction='column'
        alignItems='center'
        w={{ base: "100%", md: "420px" }}
        maxW='100%'
        background='transparent'
        borderRadius='15px'
        mx={{ base: "auto", lg: "unset" }}
        me='auto'
        mb={{ base: "20px", md: "auto" }}>
        <FormControl isRequired={true}>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select Product<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={productName} 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handleProductIdChange} >
              <option value={props.props?.location?.state?.action ? `${product_data.id}-${product_data.name}`: null} >
              {props.props?.location?.state?.action ? `${product_data.name}`: 'Select'}
                </option>
              {products.map((product,i) => {
                return <option value={`${product.id}-${product.name}`} id={i}>{product.name}</option>
              })}
          </Select>
          {/* <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select Store<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={storeName} 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handleStoreIdChange} >
              <option value={props.props?.location?.state?.action ? `${store_data.id}-${store_data.name}`: null} >
              {props.props?.location?.state?.action ? `${store_data.name}`: 'Select'}
                </option>
              {stores.map((store,i) => {
                return <option value={`${store.id}-${store.name}`} id={i}>{store.name}</option>
              })}
          </Select> */}
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select Customer<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={customerName} 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handleCustomerIdChange} >
              <option value={props.props?.location?.state?.action ? `${customer_data?.id}-${customer_data?.name}`: "0-Select"} >
              {props.props?.location?.state?.action ? `${customer_data?.name}`: 'Select'}
                </option>
              {customers.map((customer,i) => {
                return <option value={`${customer.id}-${customer.name}`} id={i}>{customer.name}</option>
              })}
          </Select>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Quantity<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='number'
            placeholder='Input the quantity to restock here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='quantity'
            onChange={handleQuantityChange}
            value={quantity}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Price per Unit<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='number'
            placeholder='Input the price per unit here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='pricePerUnit'
            onChange={handlePricePerUnitChange}
            value={price_per_unit}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select Payment mode<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={payment_mode} 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handlePaymentModeChange} >
            <option value={props.props?.location?.state?.action ? `${product_sale_data.payment_mode}`: null} >
              {props.props?.location?.state?.action ? `${product_sale_data.payment_mode}`: 'Select'}
                </option>
              <option value='mpesa' >Mpesa</option>
              <option value='equity' >Equity</option>
              <option value='cash' >Cash</option>
              <option value='other' >Other</option>
          </Select>

          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select Payment Status<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={paymentStatusName} 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handlePaymentStatusChange} >
              <option value={props.props?.location?.state?.action ? `${product_sale_data.payment_status}`: null} >
              {props.props?.location?.state?.action ? `${product_sale_data.payment_status}-Edit`: 'Select'}
                </option>
              <option value='1-Paid' >Paid</option>
              <option value='0-Not paid' >Not Paid</option>
          </Select>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Sale Date<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='date'
            placeholder='Input the date of the sale here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='sale_date'
            onChange={handleSaleDateChange}
            value={props.props?.location?.state?.action ? moment( product_sale_data.sale_date).format("YYYY-MM-DD") : sale_date} 
          />

          <Button
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            onClick={handleSubmit}
          >
            {props.props?.location?.state?.action === "edit" ? "Edit" : "Submit"}

          </Button>
          <Modal
            open={isOpenState}
            handleClick={handleModalClick}
            modalTitle="Success"
            modalBody="The link has been submited!"
          />
        </FormControl>
      </Flex>
    </Card>
  );
}


