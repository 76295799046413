export const columnsDataSuppliers = [
  {
    Header: "SUPPLIER NAME",
    accessor: "supplier.name",
  },
  {
    Header: "SUPPLIER LOCATION",
    accessor: "supplier.location",
  },
  {
    Header: "PHONE NUMBER",
    accessor: "supplier.phone_number",
  },
  {
    Header: "EMAIL",
    accessor: "supplier.email",
  },
  {
    Header: "STATUS",
    accessor: ".status",
  },
  {
    Header: "REGISTRATION DATE",
    accessor: "supplier.createdAt",
  },
  {
    Header: "VIEW",
    accessor: "supplier.id",
  }
];
