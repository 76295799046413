export const columnsDataTransactions = [
  {
    Header: "TRANSACTION ID",
    accessor: "premise_name",
  },{
    Header: "STATUS",
    accessor: "status",
  },{
    Header: "TRANSACTION TYPE",
    accessor: "action_type",
  },
  {
    Header: "AMOUNT",
    accessor: "principal_amount",
  },{
    Header: "SENDER",
    accessor: "sender",
  },{
    Header: "RECEIVER",
    accessor: "receiver",
  },
  {
    Header: "DATE",
    accessor: "created_at",
  },
];
