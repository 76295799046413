export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataLoans = [
  {
    Header: "APPROVE/DECLINE",
    accessor: "date_approved_declined",
  },
  {
    Header: "PREMISE NAME",
    accessor: "premise_name",
  },{
    Header: "STATUS",
    accessor: "status",
  },{
    Header: "LOAN TYPE",
    accessor: "loan_type",
  },
  {
    Header: "AMOUNT",
    accessor: "amount_borrowed",
  },
  {
    Header: "INTEREST",
    accessor: "interest",
  },
];
