export const columnsDataUnitMetrics = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "REGISTRATION DATE",
    accessor: "createdAt",
  },
  {
    Header: "VIEW",
    accessor: "id",
  }
];
