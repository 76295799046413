export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataUsers = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "REGISTRATION DATE",
    accessor: "createdAt",
  },
  {
    Header: "CONTACT",
    accessor: "",
  }
];
