// Custom components
  import Card from "components/card/Card.js";
  import CheckTable from "./CheckTable";
  import {columnsDataReportsDailySales} from "../variables/columnsData";
  
  // Assets
  import React, { useState, useEffect } from 'react';
  import {useToast} from "@chakra-ui/react";
  //API calls
import { GET_REPORTS_OF_A_SPECIFIC_STORE } from 'api/ApiUtils';
  
  export default function ReportDailySaleFunc(props) {
    const { ...rest } = props;
    const toast = useToast();

    const [dailySalesData, setDailySalesData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [pageNo, setPageNo] = useState(0);
    const [limit, setLimit] = useState(10);
    const [numberOfPages, setNumberOfPages] = useState(0);

  const fetchData = async (data) => {
    let response = await GET_REPORTS_OF_A_SPECIFIC_STORE(data)
    if (response.data?.status === "success"){
      const reports = response.data.data.reports;
    
      setDailySalesData(response.data.data.daily_reports.dailySalesData);


      props.setDailySaleCount(reports.totalCount)
      // const totalPages = Math.ceil(response.data.data.customers.count/limit);
      // setNumberOfPages(totalPages)
    }else if(response.data?.status === "failed") {
      toast({
        title: 'Error occured.',
        description: `${response.data.data.message}`,
        status: 'error',
        duration: 6000,
        isClosable: true,
      })
    } else {
      if(response.error.response.data.data?.message) {
        toast({
          title: 'Error occured.',
          description: `${response.error.response.data.data.message}`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      } else if(response.error.response.data.errors) {
        let errors = response.error.response.data.errors;
        errors.forEach((error) => {
          toast({
            title: 'Error occured.',
            description: `${error}`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        })
      } else {
        toast({
          title: 'Error occured.',
          description: `Try again later`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      }
    }
  }

  useEffect(() => {
    async function fetchMyAPI() {
      let data= {};
      let store_id = JSON.parse(localStorage.getItem('active_store_id'));
      data.store_id = store_id;
      data.pageNo = pageNo;
      data.limit = limit;
      data.start_date = startDate;
      data.end_date = endDate;
      await fetchData(data);
    }
    fetchMyAPI()
  }, [pageNo, limit,startDate, endDate])

  const gotoPage = async (page) => {
    setPageNo(page)
    setLimit(limit)
  }
  const handleLimitChange = async (limit) => {
    setPageNo(0);
    setLimit(limit);
  }
  const gotoNextPage = async (page) => {
    setPageNo(page+1);
    setLimit(limit);
  }
  const gotoPreviousPage = async () => {
    setPageNo(pageNo-1);
    setLimit(limit);
  }
  const handleStartDateChange = async (startDate) => {
    setStartDate(startDate);
  }

  const handleEndDateChange = async (endDate) => {
    setEndDate(endDate);
  }

    return (
      <Card p='20px' align='center' direction='column' w='100%' {...rest}>
        <CheckTable 
          columnsData={columnsDataReportsDailySales} 
          tableData={dailySalesData}
          pageNo={pageNo}
          numberOfPages={numberOfPages}
          gotoPage={gotoPage}
          handleLimitChange = {handleLimitChange}
          gotoNextPage= {gotoNextPage}
          gotoPreviousPage= {gotoPreviousPage}
          onStartDateChange ={handleStartDateChange}
          onEndDateChange ={handleEndDateChange}
        />
      </Card>
    );
  }
  