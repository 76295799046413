import React, { useState, useEffect } from 'react';
import {
    Flex,
    IconButton,
    Text,
    Tooltip,
    Select,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper
  } from "@chakra-ui/react";
  import {
    ArrowRightIcon,
    ArrowLeftIcon,
    ChevronRightIcon,
    ChevronLeftIcon
  } from "@chakra-ui/icons";

  
  
  function PaginatorComponent(props) {
    const [pageSize, setPageSize] = useState(10);
    const [canPreviousPage, setCanPreviousPage] = useState(true);
    const [canNextPage, setCanNextPage] = useState(true);

    const gotoPage = (page) => { 
        if (page == 0) {
            setCanNextPage(true);
            setCanPreviousPage(false);
        }
        if (page == props.numberOfPages - 1) {
            setCanNextPage(false);
            setCanPreviousPage(true);
        }
        props.gotoPage(page);
    }
    const handlePageSizeChange = (e) => {
        setPageSize(Number(e.target.value));
        props.handleLimitChange(Number(e.target.value));
    }
    const gotoNextPage= () => {
        props.gotoNextPage();
    }
    const gotoPreviousPage= () => {
        props.gotoPreviousPage();
    }
    
  return (
    <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex>
          <Tooltip label="First Page">
            <IconButton
              onClick={() => gotoPage(0)}
              isDisabled={!canPreviousPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              // onClick={gotoPreviousPage}
              isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex alignItems="center">
          <Text flexShrink="0" mr={8}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {props.pageNo + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {props.numberOfPages}
            </Text>
          </Text>
          <Text flexShrink="0">Go to page:</Text>{" "}
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={props.numberOfPages}
            onChange={(value) => {
              const page = value ? value - 1 : 0;
              gotoPage(page);
            }}
            defaultValue={props.pageNo + 1}
            value={props.pageNo + 1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            w={32}
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Tooltip label="Next Page">
            <IconButton
            //   onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              onClick={() => gotoPage(props.numberOfPages - 1)}
              isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
      </Flex>
  );
}

export default PaginatorComponent;
