import React, { useState } from 'react';
import { useHistory } from 'react-router';
import {
  Flex,
  Button,
  Text,
  Select,
  FormControl,
  FormLabel,
  Input,
  Link,
  Icon,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";


import IconBox from "components/icons/IconBox";
import { IoMdEye, IoMdArrowForward } from "react-icons/io";

// Custom components
import Card from "components/card/Card";
import Modal from "components/Modal";

  //API calls
  import {ADD_TRANSACTION} from 'api/ApiUtils';

export default function AddProductRestockForm(props) {
  const toast = useToast();
  const history = useHistory();

  const [isOpenState, setIsOpenState] = useState(false);
  const [sale_id, setSaleId] = useState('');
  const [restock_id, setRestockId] = useState('');
  const [trx_code, setTrxCode] = useState('');
  const [third_party_name, setThirdPartyName] = useState('');
  const [third_party_trx_code, setThirdPartyTrxCode] = useState('');
  const [status, setStatus] = useState('');
  const [statusName, setStatusName] = useState('');

  const handleSaleIdChange = (event) => setSaleId(event.target.value);
  const handleRestockIdChange = (event) => setRestockId(event.target.value);
  const handleTrxCodeChange = (event) => setTrxCode(event.target.value);
  const handleThirdPartyNameChange = (event) => setThirdPartyName(event.target.value);
  const handleThirdPartyTrxCodeChange = (event) => setThirdPartyTrxCode(event.target.value);
  const handleStatusIdChange = (event) => {
    setStatus(event.target.value[0]);
    setStatusName(event.target.value);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let data = {
      sale_id,
      restock_id,
      trx_code,
      third_party_name,
      third_party_trx_code,
      status
    }

    let response = await ADD_TRANSACTION(data);
    if(response.data?.status === "success") {
      history.push({pathname:'/admin/transactions'})
    }else if(response.data?.status === "failed") {
      toast({
        title: 'Error occured.',
        description: `${response.data.data.message}`,
        status: 'error',
        duration: 6000,
        isClosable: true,
      })
    } else {
      if(response.error.response.data.data?.message) {
        toast({
          title: 'Error occured.',
          description: `${response.error.response.data.data.message}`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      } else if(response.error.response.data.errors) {
        let errors = response.error.response.data.errors;
        errors.forEach((error) => {
          toast({
            title: 'Error occured.',
            description: `${error}`,
            status: 'error',
            duration: 6000,
            isClosable: true,
          })
        })
      } else {
        toast({
          title: 'Error occured.',
          description: `Try again later`,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
      }
    }
  }

  // Chakra color mode
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const handleModalClick = () => {

    setIsOpenState(!isOpenState)
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='30px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex alignItems='center' w='100%' mb='30px'>
        <IconBox
          me='12px'
          w='38px'
          h='38px'
          bg={boxBg}
          icon={<Icon as={IoMdEye} color={brandColor} w='24px' h='24px' />}
        />
        <Text color={textColor} fontSize='lg' fontWeight='700'>
          {props.props?.location?.state?.action === "edit" ? "Edit Transaction" : "Add a Transaction"}
        </Text>
        <Link
          w='100%'
          href='#/admin/transactions'>  
          <IconBox
            me='12px'
            w='38px'
            h='38px'
            bg={boxBg}
            ms='auto'
            icon={<Icon as={IoMdArrowForward} color={brandColor} w='24px' h='24px' />}
          />
          </Link>
      </Flex>
      <Flex
        zIndex='2'
        direction='column'
        alignItems='center'
        w={{ base: "100%", md: "420px" }}
        maxW='100%'
        background='transparent'
        borderRadius='15px'
        mx={{ base: "auto", lg: "unset" }}
        me='auto'
        mb={{ base: "20px", md: "auto" }}>
        <FormControl isRequired={true}>
        <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Sale Id<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='number'
            placeholder='Input the sale Id here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='sale_id'
            onChange={handleSaleIdChange}
            value={sale_id}
          />
            <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Restock Id<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='number'
            placeholder='Input the Restock Id here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='restock_id'
            onChange={handleRestockIdChange}
            value={restock_id}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Transaction Code<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Input the transaction code here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='trx_code'
            onChange={handleTrxCodeChange}
            value={trx_code}
          />
            <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Third party name<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Input the third party payment provider name here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='third_party_name'
            onChange={handleThirdPartyNameChange}
            value={third_party_name}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Third party trx code<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='Input the third party trx code here.'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='third_party_trx_code'
            onChange={handleThirdPartyTrxCodeChange}
            value={third_party_trx_code}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Select Trx status<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder={statusName} 
            mb='24px' 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' 
            onChange={handleStatusIdChange} >
              <option value='select'>Select</option>
              <option value='1-completed'>Completed</option>
              <option value='0-not completed'>Not completed</option>
              <option value='2-in progress'>In progress</option>
              <option value='3-reversed'>Reversed</option>
          </Select>

          <Button
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            onClick={handleSubmit}
          >
            {props.props?.location?.state?.action === "edit" ? "Edit" : "Submit"}

          </Button>
          <Modal
            open={isOpenState}
            handleClick={handleModalClick}
            modalTitle="Success"
            modalBody="The link has been submited!"
          />
        </FormControl>
      </Flex>
    </Card>
  );
}


