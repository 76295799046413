export const columnsDataRoleUsers = [
  {
    Header: "USER",
    accessor: "user.user_details[0].first_name",
  },
  {
    Header: "STORE",
    accessor: "store.name",
  },
  {
    Header: "ROLE",
    accessor: "role.name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "REGISTRATION DATE",
    accessor: "createdAt",
  },
  {
    Header: "VIEW",
    accessor: "id",
  }
];
