export const columnsDataEmployees = [
  {
    Header: "NAME",
    accessor: "user.user_details[0].first_name",
  },
  {
    Header: "STORE",
    accessor: "store_id",
  },
  {
    Header: "SALARY",
    accessor: "salary",
  },{
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "REGISTRATION DATE",
    accessor: "createdAt",
  },
  {
    Header: "VIEW",
    accessor: "id",
  }
];
