// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Banner from "./components/Banner";
import Card from "components/card/Card.js";
import General from "views/admin/profile/components/General";
import Notifications from "views/admin/profile/components/Notifications";
import Projects from "views/admin/profile/components/Projects";
import Storage from "views/admin/profile/components/Storage";
import Upload from "views/admin/profile/components/Upload";

//loans display table
import LoanCheckTable from "../loans/components/CheckTable";
import {  columnsDataLoans } from "../loans/variables/columnsData"

//transactions display table
import TrxCheckTable from "../transactions/components/CheckTable";
import {  columnsDataTransactions } from "../transactions/variables/columnsData"

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import React, { useState, useEffect } from 'react';

  //API calls
  import { FetchShopWalletByPremiseID,FetchShopWallet, FetchPremiseLoans, FetchPremiseLoansTransactions } from 'api/ApiUtils';

export default function ShopAccount(props) {
  
  const [shop, setShop] = useState({});
  const [shopWallet, setShopWallet] = useState({});
  const [shopLoans, setShopLoans] = useState({});
  const [shopTransactions, setShoptransactions] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0)
  const [limit, setLimit] = useState(50)
  const [Pages, setPages] = useState(0)

  useEffect(() => {
    async function fetchMyAPI() {
      let shopInfo = props.location.state.data;
      // console.log(shopWalletResponse);
      let shopData = {};
      shopInfo.map((row, index) => {
        let id = row.column.id
        let value = row.value
        shopData[id] = value
        
      })
     
      setShop(shopData)
      //get the shop wallet
      const data = {
        premise_id :props.location.state.premise_id,
        user_id: shopData.user_id,
        paylend_number: shopData.paylend_number
      };
    
      let shopWalletResponse = await FetchShopWallet(data)
      setShopWallet(shopWalletResponse.data.data[0])

      //get the shop loans
      let shopLoansresponse = await FetchPremiseLoans(data)
      setShopLoans(shopLoansresponse.data.data)
      //get the shop transactions
      let shopTransactionsResponse = await FetchPremiseLoansTransactions (data)
      setShoptransactions(shopTransactionsResponse.data.data)
    }
    fetchMyAPI()
  }, [])

  // console.log(shop)

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          // lg: "1.34fr 1fr 1.62fr",
        }}
        templateRows={{
          // base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          // gridArea='1 / 1 / 2 / 2'
          gridArea='auto'
          banner={shop.premise_front_image}
          avatar={shop.premise_front_image}
          name= {`${shop.premise_name}- ${shop.paylend_number}`}
          job={`${shop.manager_firstname} ${shop.manager_lastname} - ${shop.manager_phone_number}`}
          balance={`${shopWallet.available_balance} ksh`}
          repayable={`${shopWallet.repayable_amount} ksh`}
          creditLimit={`${shopWallet.credit_limit} ksh`}
        />
    
      </Grid>
    <Grid
      mb='20px'
    >
      {shopLoans.length > 0? <LoanCheckTable columnsData={columnsDataLoans} tableData={shopLoans}/> : ""}
    </Grid>

    <Grid
      mb='20px'
    >
      {shopTransactions.length > 0? <TrxCheckTable columnsData={columnsDataTransactions} tableData={shopTransactions}/> : ""}
    </Grid>

    <Grid mb='20px'>
      <Box
        bg={`url(${shop.business_permit})`}
        bgSize='cover'
        borderRadius='16px'
        h='500px'
        w='100%'
      />
    </Grid>

    <Grid mb='20px'>
      <Box
        bg={`url(${shop.premise_front_image})`}
        bgSize='cover'
        borderRadius='16px'
        h='500px'
        w='100%'
      />
    </Grid>

    <Grid mb='20px'>
      <Box
        bg={`url(${shop.mpesa_statement})`}
        bgSize='cover'
        borderRadius='16px'
        h='500px'
        w='100%'
      />
    </Grid>

    <Grid mb='20px'>
      <Box
        bg={`url(${shop.manager_identification})`}
        bgSize='cover'
        borderRadius='16px'
        h='500px'
        w='100%'
      />
    </Grid>

    </Box>
  );
}
