// Chakra imports
import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useState } from 'react';

import {MdShop2} from "react-icons/md";

//custom imports
import CustomerList from './components/CustomerList';

export default function Customers() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [count, setCustomerCount] = useState(0)

  const customerCount = (count) =>{
    setCustomerCount(count)
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdShop2} color={brandColor} />
              }
            />
          }
          name='Total Customers'
          value={count}
        />
      </SimpleGrid>

      <CustomerList setCustomerCount={customerCount}/>

    </Box>
  );
}
