// Chakra imports
import {
  Box,
} from "@chakra-ui/react";

import React from 'react';


//custom imports
import AddProductForm from './components/AddProductForm';

export default function Links(props) {
  // Chakra Color Mode

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <AddProductForm props={props} />

    </Box>
  );
}
