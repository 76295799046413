
  // Custom components
  import Card from "components/card/Card.js";
  import CheckTable from "./CheckTable";
  import {
    columnsDataUsers,
  } from "../variables/columnsData";
  
  // Assets
  import React, { useState, useEffect } from 'react';

  //API calls
import { BusinessLeadsList } from 'api/ApiUtils';
// import {LINK_DIRECTORY_RESPONSE} from 'api/dummyResponses'
  
  export default function SiteListFunc(props) {
    const { ...rest } = props;

  const [usersList, setUsersList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0)
  const [limit, setLimit] = useState(50)
  const [Pages, setPages] = useState(0)

  useEffect(() => {
    async function fetchMyAPI() {
      let userData = JSON.parse(localStorage.getItem('user'))
      let data = {
        user_id: userData.id
      }
      let response = await BusinessLeadsList(data)
      setUsersList(response.data.data.business_leads.rows)
    }
    fetchMyAPI()
  }, [])
  
    return (
      <Card p='20px' align='center' direction='column' w='100%' {...rest}>
        <CheckTable columnsData={columnsDataUsers} tableData={usersList}/>
      </Card>
    );
  }
  