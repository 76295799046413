export const columnsDataProducts = [
  {
    Header: "PRODUCT NAME",
    accessor: "name",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
  {
    Header: "CATEGORY",
    accessor: "product_category.name",
  },{
    Header: "UNITS",
    accessor: "unit",
  },
  {
    Header: "REGISTRATION DATE",
    accessor: "createdAt",
  },
  {
    Header: "VIEW",
    accessor: "id",
  }
];
