export const columnsDataProductRestocks = [
  {
    Header: "PRODUCT",
    accessor: "product.name",
  },
  {
    Header: "STORE",
    accessor: "store.name",
  },
  {
    Header: "SUPPLIER",
    accessor: "supplier.name",
  },{
    Header: "QUANTITY",
    accessor: "quantity",
  },{
    Header: "PRICE/UNIT",
    accessor: "price_per_unit",
  },{
    Header: "INSTOCK",
    accessor: "instock",
  },{
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "RESTOCK DATE",
    accessor: "restock_date",
  },
  {
    Header: "REGISTRATION DATE",
    accessor: "createdAt",
  },
  {
    Header: "VIEW",
    accessor: "id",
  }
];
