export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataShops = [
  {
    Header: "SHOP NAME",
    accessor: "premise_name",
  },
  {
    Header: "SHOP NUMBER",
    accessor: "paylend_number",
  },
  {
    Header: "TYPE",
    accessor: "premise_type",
  },{
    Header: "VERIFIED",
    accessor: "is_premise_verified",
  },
  {
    Header: "MANAGER PHONE",
    accessor: "manager_phone_number",
  },
  {
    Header: "REGISTRATION DATE",
    accessor: "created_at",
  },{
    Header: "VIEW",
    accessor: "id",
  },
  {
    Header: "MANAGER EMAIL",
    accessor: "manager_email",
  },
  {
    Header: "MANAGER FNAME",
    accessor: "manager_firstname",
  }, {
    Header: "MANAGER LNAME",
    accessor: "manager_lastname",
  },
  {
    Header: "BUSINESS PERMIT",
    accessor: "business_permit",
  },{
    Header: "INSTITUTIONAL LEVEL",
    accessor: "institutional_level",
  },
  {
    Header: "IS MANAGER",
    accessor: "is_manager",
  },
  {
    Header: "MANAGER ID CARD",
    accessor: "manager_identification",
  },{
    Header: "MONTHLY TRX INCOME",
    accessor: "monthly_transaction_income",
  }, {
    Header: "MPESA STATEMENT",
    accessor: "mpesa_statement",
  },
  {
    Header: "PREMISE ADDRESS",
    accessor: "premise_address",
  },{
    Header: "PREMISE FRONT",
    accessor: "premise_front_image",
  },  {
    Header: "PREMISE PHONE",
    accessor: "premise_phone",
  },
  {
    Header: "LAST RECORD UPDATE",
    accessor: "updated_at",
  },  {
    Header: "MANAGER SYSTEM ID",
    accessor: "user_id",
  },

];
