// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import React, { useState, useEffect } from 'react';

import {
  MdPeople,
  MdShop2,
} from "react-icons/md";

//custom imports
import UsersList from './components/UsersList';

export default function Shops() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      <UsersList/>

    </Box>
  );
}
