// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Banner from "./components/Banner";
import Modal from "components/Modal";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar.png";
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

//API imports
import { GET_A_USER } from 'api/ApiUtils';

export default function Overview() {
  const history = useHistory();
    //states declaration
    const [user, setUser] = useState({});
    const [isOpenState, setIsOpenState] = useState(false);

    useEffect(() => {
      async function fetchMyAPI() {
        let data = {};
        let userData = JSON.parse(localStorage.getItem('user'))
        data = {
          user_id: userData.id
        }
        let response = await GET_A_USER(data)
        if(response.data.data.user.user_details.length<1) {
          setIsOpenState(true);
        }
        setUser(response.data.data.user);
    
      }
      fetchMyAPI()
    }, [])

    const handleModalClick = () => {
      history.push({pathname:'/admin/add-users'})
    }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr 1fr 1.62fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea='1 / 1 / 2 / 2'
          banner={banner}
          avatar={avatar}
          name={user?.user_details?.[0]?.first_name +' ' + user?.user_details?.[0]?.last_name}
          job={user?.email}
          phone={user?.user_details?.[0]?.phone_number}
          national_id={user?.user_details?.[0]?.national_id}
          system_id={user?.id}
        />
      </Grid>
      <Modal
        open={isOpenState}
        handleClick={handleModalClick}
        modalTitle="More Details Required"
        modalBody="Please provide more information about you to proceed"
        modolButtonText='Proceed to form'
      />
    </Box>
  );
}
