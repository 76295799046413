export const columnsDataReportsRestocks = [
  {
    Header: "PRODUCT NAME",
    accessor: "product_name",
  },
  {
    Header: "TOTAL QUANTITY",
    accessor: "total_quantity",
  },  {
    Header: "TOTAL INSTOCK",
    accessor: "total_instock",
  },
  {
    Header: "TOTAL AMOUNT SPENT",
    accessor: "total_amount_spent",
  },{
    Header: "PRODUCT ID",
    accessor: "product_id",
  },
  {
    Header: "VIEW",
    accessor: "id",
  }
];
