export const columnsDataProductSale = [
  {
    Header: "PRODUCT",
    accessor: "product",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "PRICE/UNIT",
    accessor: "price_per_unit",
  },
  {
    Header: "PROFIT",
    accessor: "profit",
  },
  {
    Header: "PAYMENT MODE",
    accessor: "payment_mode", 
  },
  {
    Header: "PAYMENT STATUS",
    accessor: "payment_status",
  },{
    Header: "STORE",
    accessor: "store", 
  },
  {
    Header: "CUSTOMER",
    accessor: "customer",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "SALE DATE",
    accessor: "sale_date",
  },
  {
    Header: "VIEW",
    accessor: "id",
  }
];
