import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  Flex,
  Button,
  Select,
  Text,
  FormControl,
  FormLabel,
  Input,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";


import IconBox from "components/icons/IconBox";
import { IoMdEye, IoIosAdd } from "react-icons/io";

// Custom components
import Card from "components/card/Card";
import Modal from "components/Modal";

  //API calls
  import { AddSite } from 'api/ApiUtils';

export default function AddSiteForm(props) {

  const history = useHistory();

  const [linkOfferData, setLinkOfferData] = useState({});
  const [isOpenState, setIsOpenState] = useState(false);
  const [siteName, setSiteName] = useState();
  const [siteLink, setSiteLink] = useState('');
  const [category, setCategory] = useState('');
  const [monthlyTrafic, setMonthlyTraffic] = useState(0);
  const [domainAuthority, setDomainAuthority] = useState(0);
  const [language, setLanguage] = useState('ENG');



  useEffect(() => {
    async function fetchMyAPI() {
      if (props.props?.location?.state?.action === "edit") {

        const offerInfo = props.props.location.state.data
        let offerData = {};
        offerInfo.map((row, index) => {
          let id = row.column.id
          let value = row.value
          offerData[id] = value

        })

        setLinkOfferData(offerData)
      }
    }
    fetchMyAPI()
  }, [])

  const handleSiteNameChange = (event) => setSiteName(event.target.value);
  const handleSiteLinkChange = (event) => setSiteLink(event.target.value);
  const handleCategoryChange = (event) => setCategory(event.target.value);
  const handleMonthlyTrafficChange = (event) => setMonthlyTraffic(event.target.value);
  const handleDomainAuthorityChange = (event) => setDomainAuthority(event.target.value);
  const handleLanguageChange = (event) => setLanguage(event.target.value);



  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      name: siteName,
      url: siteLink,
      category,
      monthly_traffic: monthlyTrafic,
      domain_authority: domainAuthority,
      language:language
    }

    let response = await AddSite(data)
  
    if(response.data.status === "success") {
      history.push({pathname:'/admin/my-sites'})
    }
  }

  // Chakra color mode
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const handleModalClick = () => setIsOpenState(!isOpenState);

  return (
    <Card
      direction='column'
      w='100%'
      px='30px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex alignItems='center' w='100%' mb='30px'>
        <IconBox
          me='12px'
          w='38px'
          h='38px'
          bg={boxBg}
          icon={<Icon as={IoMdEye} color={brandColor} w='24px' h='24px' />}
        />
        <Text color={textColor} fontSize='lg' fontWeight='700'>
          {props.props?.location?.state?.action === "edit" ? "Edit Site" : "Add a Site"}
        </Text>
        <IconBox
          me='12px'
          w='38px'
          h='38px'
          bg={boxBg}
          ms='auto'
          icon={<Icon as={IoIosAdd} color={brandColor} w='24px' h='24px' />}
        />
      </Flex>
      <Flex
        zIndex='2'
        direction='column'
        alignItems='center'
        w={{ base: "100%", md: "420px" }}
        maxW='100%'
        background='transparent'
        borderRadius='15px'
        mx={{ base: "auto", lg: "unset" }}
        me='auto'
        mb={{ base: "20px", md: "auto" }}>
        <FormControl isRequired={true}>
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Site Name<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='The Backlink Project'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='siteName'
            onChange={handleSiteNameChange}
            value={props.props?.location?.state?.action ? linkOfferData.site_name : siteName}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Site Link<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='https://www.thebacklinkproject.com/'
            mb='24px'
            fontWeight='500'
            size='lg'
            name='postLink'
            onChange={handleSiteLinkChange}
            value={props.props?.location?.state?.action ? linkOfferData.post_link : siteLink}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Category<Text color={brandStars}>*</Text>
          </FormLabel>
        
          <Select 
            placeholder='Select site category' 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' onChange={handleCategoryChange} >
              <option value='business' >Business</option>
              <option value='technology' >Technology</option>
              <option value='beauty' >Beauty</option>
              <option value='education'>Education</option>
          </Select>
                    
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Monthly Traffic<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='email'
            placeholder='20,000'
            mb='24px'
            fontWeight='500'
            size='lg'
            onChange={handleMonthlyTrafficChange}
            value={props.props?.location?.state?.action ? linkOfferData.monthly_traffic : monthlyTrafic}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Domain Authority<Text color={brandStars}>*</Text>
          </FormLabel>
          <Input
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='email'
            placeholder='45'
            mb='24px'
            fontWeight='500'
            size='lg'
            onChange={handleDomainAuthorityChange}
            value={props.props?.location?.state?.action ? linkOfferData.domain_authority : domainAuthority}
          />
          <FormLabel
            display='flex'
            ms='4px'
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'>
            Language<Text color={brandStars}>*</Text>
          </FormLabel>
          <Select 
            placeholder='Select language' 
            mb='24px' value={"test"} 
            ms={{ base: "0px", md: "0px" }}
            fontSize='sm'
            size='lg'
            variant='outline' onChange={handleLanguageChange} >
              <option value='english' >English</option>
              <option value='french' >French</option>
              <option value='german' >German</option>
              <option value='swahili'>Swahili</option>
          </Select>

          <Button
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
            h='50'
            mb='24px'
            onClick={handleSubmit}
          >
            {props.props?.location?.state?.action === "edit" ? "Edit" : "Submit"}

          </Button>
          <Modal
            open={isOpenState}
            handleClick={handleModalClick}
            modalTitle="Success"
            modalBody="The link has been submited!"
          />
        </FormControl>
      </Flex>
    </Card>
  );
}


