import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    useDisclosure
  } from '@chakra-ui/react'

  import React, { useEffect } from 'react';



  export default function BacklinkModal(props) {

    const OverlayOne = () => (
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px) hue-rotate(90deg)'
      />
    )
  
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        async function funcOnMount() {
    
          
            if(props.open === true) {
                         onOpen()
                     } else  {
                      onClose()
                     }
        }
        funcOnMount()
    }, [props])

    return (
      <>
        <Modal onOpen={onOpen} isCentered isOpen={isOpen} onClose={onClose}>
        <OverlayOne />
          <ModalContent>
            <ModalHeader>{props.modalTitle}</ModalHeader>
            <ModalCloseButton onClick={props.handleClick}/>
            <ModalBody>
              <Text>{props.modalBody}</Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={props.handleClick}>{props.modolButtonText? props.modolButtonText: 'Close'}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }