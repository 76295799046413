// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect } from 'react';

import {
  MdPeople,
  MdShop2,
} from "react-icons/md";

//custom imports
import ShopList from './components/ShopList';

export default function Shops() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [count, setShopCount] = useState(0)

  const shopCount = (count) =>{
    setShopCount(count)
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdPeople} color={brandColor} />
              }
            />
          }
          name='Total users'
          value='n/a'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdShop2} color={brandColor} />
              }
            />
          }
          name='Shops'
          value={count}
        />
        <MiniStatistics growth='+23%' name='End Consumers' value='n/a' />
      </SimpleGrid>

      <ShopList setShopCount={shopCount}/>

    </Box>
  );
}
