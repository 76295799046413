// Chakra imports
import {
  Box,
} from "@chakra-ui/react";

import React, { useState, useEffect } from 'react';


//custom imports
import AddStoreForm from './components/AddStoreForm';

export default function Links(props) {
  // Chakra Color Mode

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <AddStoreForm props={props} />

    </Box>
  );
}
