// Chakra imports
import {
    Box,
    Grid,
    Link,
    useColorModeValue,
    Icon
} from "@chakra-ui/react";

// Custom components
import Banner from "./Banner";
import React, { useState, useEffect } from 'react';
import { useToast } from "@chakra-ui/react";


// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";

import { IoIosAdd } from "react-icons/io";


//API calls
import { GET_STORES_OF_A_SPECIFIC_USER, GET_STORES_WHERE_USER_IS_EMPLOYED } from 'api/ApiUtils';


export default function StoresBannerList(props) {
    const brandColor = useColorModeValue("brand.500", "brand.400");

    const { ...rest } = props;
    const toast = useToast();

    const [shopList, setShopList] = useState([]);
    const [employedShopList, setEmployedShopList] = useState([]);
    const [pageNo, setPageNo] = useState(0);
    const [limit, setLimit] = useState(10);

    const fetchData = async (data) => {
        let response = await GET_STORES_OF_A_SPECIFIC_USER(data)
        let employment_response = await GET_STORES_WHERE_USER_IS_EMPLOYED(data);
        if (response.data?.status === "success" && employment_response.data?.status === "success") {
            setShopList(response.data.data.stores.rows)
            setEmployedShopList(employment_response.data.data.stores.rows)
            localStorage.setItem('stores', JSON.stringify(response.data.data.stores.rows));
            localStorage.setItem('employed_stores', JSON.stringify(employment_response.data.data.stores.rows));
            
        } else if (response.data?.status === "failed") {
            toast({
                title: 'Error occured.',
                description: `${response.data.data.message}`,
                status: 'error',
                duration: 6000,
                isClosable: true,
            })
        } else {
            if (response.error.response.data.data?.message) {
                toast({
                    title: 'Error occured.',
                    description: `${response.error.response.data.data.message}`,
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            } else if (response.error.response.data.errors) {
                let errors = response.error.response.data.errors;
                errors.forEach((error) => {
                    toast({
                        title: 'Error occured.',
                        description: `${error}`,
                        status: 'error',
                        duration: 6000,
                        isClosable: true,
                    })
                })
            } else {
                toast({
                    title: 'Error occured.',
                    description: `Try again later`,
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                })
            }
        }
    }

    useEffect(() => {
        async function fetchMyAPI() {
            let user = JSON.parse(localStorage.getItem('user'));
            let data = {};
            data.user_id = user.id;
            data.pageNo = pageNo;
            data.limit = limit;
            await fetchData(data);
        }
        fetchMyAPI()
    }, [pageNo, limit])


    return (
        <Box >
            <Grid
                templateColumns={{
                    base: "1fr",
                    lg: "1.34fr 1fr 1.62fr",
                }}
                templateRows={{
                    base: "repeat(3, 1fr)",
                    lg: "1fr",
                }}
                gap={{ base: "20px", xl: "20px" }}>
                <Link
                    href='#/admin/add-stores'>
                    <Banner
                        gridArea='1 / 1 '
                        banner={banner}
                        avatar={<Icon as={IoIosAdd} color={brandColor} w='24px' h='24px' />}
                        name={<Icon as={IoIosAdd} color={brandColor} w='50px' h='50px' />}
                        CTA = {'Add'}
                    // job={`Role-Employee`}
                    // location={shop.location}
                    // OwnerID={shop.user_id}
                    // following='-'
                    />
                </Link>
                {shopList.map((shop, index) => (
                    <Link
                        href={`#/admin/store-page/${shop.id}`}>
                        <Banner
                            key={index}
                            gridArea='1 / 1 '
                            banner={banner}
                            avatar={avatar}
                            name={shop.name}
                            job={`Role-Owner`}
                            CTA = {'View'}
                        // location={shop.location}
                        // OwnerID={shop.user_id}
                        // following='-'
                        />
                    </Link>
                ))}
                {employedShopList.map((shop, index) => (
                    <Link
                        href={`#/admin/store-page/${shop.store.id}`}>
                        <Banner
                            key={index}
                            gridArea='1 / 1 '
                            banner={banner}
                            avatar={avatar}
                            name={shop.store.name}
                            job={`Role-Employee`}
                            CTA = {'View'}
                        // location={shop.location}
                        // OwnerID={shop.user_id}
                        // following='-'
                        />
                    </Link>
                ))}

            </Grid>
        </Box>
    );
}
