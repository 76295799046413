
// Chakra imports
import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
  Link
} from "@chakra-ui/react";

// Custom components
import GenralStoreReports from "../reports/index"
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React from "react";
import { useParams } from 'react-router-dom';
import {
  MdLocalMall,
  MdLocalShipping,
  MdArrowForward 
} from "react-icons/md";


export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const { store_id } = useParams();
  localStorage.setItem('active_store_id', JSON.stringify(store_id));
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <GenralStoreReports/>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
          <Link
            href='#/admin/employees'>
        <MiniStatistics
          name='-'
          value='Employees'
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdArrowForward } color={brandColor} />
              }
            />
          }
        /></Link>
        <Link
            href='#/admin/suppliers'>
        <MiniStatistics
          name='-'
          value='Suppliers'
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdLocalShipping } color={brandColor} />
              }
            />
          }
        /></Link>
        <Link
            href='#/admin/products'>
        <MiniStatistics
          name='-'
          value='Products'
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdLocalMall } color={brandColor} />
              }
            />
          }
        /></Link>
        <Link
            href='#/admin/product-categories'>
      <MiniStatistics
          name='-'
          value='Product Categories'
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdArrowForward } color={brandColor} />
              }
            />
          }
        /></Link>
        <Link
            href='#/admin/product-restocks'>
        <MiniStatistics
          name='-'
          value='Product Restocks'
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdArrowForward } color={brandColor} />
              }
            />
          }
        /></Link>
          <Link
            href='#/admin/product-sales'>
        <MiniStatistics
          name='-'
          value='Product Sales'
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdArrowForward } color={brandColor} />
              }
            />
          }
        /></Link>
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <Link
          href='#/admin/unit-metrics'>
          <MiniStatistics
            name='-'
            value='Unit metrics'
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdArrowForward} color={brandColor} />
                }
              />
            }
          />
        </Link>
        <Link
          href='#/admin/customers'>
          <MiniStatistics
            name='-'
            value='Customers'
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdArrowForward} color={brandColor} />
                }
              />
            }
          />
        </Link>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <Link
          href='#/admin/reports_daily_sales'>
          <MiniStatistics
            name='-'
            value='Reports Daily Sales'
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdArrowForward} color={brandColor} />
                }
              />
            }
          />
        </Link>
        <Link
          href='#/admin/reports_restocks'>
          <MiniStatistics
            name='-'
            value='Reports Restocks'
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdArrowForward} color={brandColor} />
                }
              />
            }
          />
        </Link>
      </SimpleGrid>      
    </Box>
  );
}
