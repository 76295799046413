export const columnsDataReportsDailySales = [
  {
    Header: "SALE DATE",
    accessor: "sale_date",
  },
  {
    Header: "TOTAL QUANTITY",
    accessor: "total_quantity",
  },  {
    Header: "TOTAL PROFITS",
    accessor: "total_profit",
  },
  {
    Header: "TOTAL VOLUME AMOUNT",
    accessor: "total_volume_amount",
  },{
    Header: "SALE COUNT",
    accessor: "sales_count",
  },
  {
    Header: "VIEW",
    accessor: "id",
  }
];
