import {
  Flex,
  Table,
  Button,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Icon,
  Link,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useHistory } from "react-router";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import moment from "moment";

import IconBox from "components/icons/IconBox";
import { IoMdEye, IoIosAdd } from "react-icons/io";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";


export default function CheckTable(props) {
  const history = useHistory();
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 100;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const buttonColor = useColorModeValue("Gray.900", "gray");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex alignItems='center' w='100%' mb='30px'>
          <IconBox
            me='12px'
            w='38px'
            h='38px'
            bg={boxBg}
            icon={<Icon as={IoMdEye} color={brandColor} w='24px' h='24px' />}
          />
          <Text color={textColor} fontSize='lg' fontWeight='700'>
            Users
          </Text>
          <Link
          w='100%'
          href='#/admin/add-sites'>  
          <IconBox
            me='12px'
            w='38px'
            h='38px'
            bg={boxBg}
            ms='auto'
            icon={<Icon as={IoIosAdd} color={brandColor} w='24px' h='24px' />}
          />
          </Link>

        </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "ID") {
                    data = (
                      <Flex align='center'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "EMAIL") {
                    data = (
                      <Flex align='center'>
                        <Link
                          me='10px'
                          color={textColor}
                          fontSize='sm'
                          href={cell.value}
                          fontWeight='700'>
                          {cell.value}
                        </Link>
                      </Flex>
                    );
                  } else if (cell.column.Header === "STATUS") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "REGISTRATION DATE") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {moment(cell.value).format("MMM Do YYYY")}
                      </Text>
                    );
                  }  else if (cell.column.Header === "CONTACT") {
                    data = (
                      <Button 
                        color={buttonColor} 
                        fontSize='sm' 
                        onClick={()=>history.push({
                          pathname:'/admin/add-sites',
                          state: {
                            action: "edit",
                            data: row.cells
                          }
                        })}
                        fontWeight='700'>
                        {'Contact User...'}
                      </Button>
                    );
                  }else {
                    return
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
